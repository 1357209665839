import $http from "@/request/http";

// 期刊查询
export const getJournalList =  (params) => $http.getFhl("/journal/journalPage", params);

// 申请使用期刊表单
export const applyJournal = (params) => $http.postFhl("/journal/inserJournalForm", params);

// 查询论文名列表
export const getPaperList = (params) => $http.getFhl("/journal/getLinkList", params);

// 查询使用期刊数量
export const getUseJournalCount = (params) => $http.getFhl("/journal/getCountNum", params);