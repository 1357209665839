<template>
    <div class="new_cases">
        <div class="new_cases_left">
            <div class="left_title">NEW CASES</div>

            <el-collapse v-model="activeName" :accordion="true" class="left_collapse">
                <el-collapse-item :title="item.title" :name="index.toString()"
                    :class="index == activeName ? 'new_cases_active left_collapse_item' : 'left_collapse_item'"
                    v-for="(item, index) in collapseList" :key="index" :ref="'collapse' + index">
                    <template slot="title">
                        <div class="title_box" @mouseover="onCollapseMouseenter(index)" v-if="!isMobile">
                            <div class="title_text" @click="goToBlank(item.url)">{{ item.title }}</div>
                        </div>
                        <div class="title_box" v-else>
                            <div class="title_text" @click="goToBlank(item.url)">{{ item.title }}</div>
                        </div>
                    </template>

                    <ExpandableList :items="item.periodicalList"></ExpandableList>
                </el-collapse-item>
            </el-collapse>

        </div>
        <div class="new_cases_right">
            <img :src="collapseList[Number(activeName)].image" />
            <div class="overlay" :class="{ 'fade-in': isFading }"></div>
        </div>
    </div>
</template>

<script>
import { collapseList } from "./data"
import ExpandableList from "@/components/expandableList/index.vue"

export default {
    components: {
        ExpandableList
    },
    data() {
        return {
            activeName: '0',
            isFading: false,
            collapseList,
            isMobile: false
        }
    },
    methods: {
        goToBlank(url) {
            window.open(url, '_blank')
        },
        updateScreenWidth() {
            this.isMobile = window.innerWidth < 768;
        },
        onCollapseMouseenter(index) {
            const collapseRef = this.$refs['collapse' + index][0]
            const flag = collapseRef.isActive

            if (!flag) {
                this.isFading = true;
                this.activeName = index

                this.$nextTick(() => {
                    collapseRef.handleHeaderClick()
                })

                setTimeout(() => {
                    this.isFading = false;
                }, 200);
            }
        }
    },
    mounted() {
        this.updateScreenWidth();
        window.addEventListener("resize", this.updateScreenWidth);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateScreenWidth);
    },
}
</script>

<style lang="scss">
@import "../../assets/css/minix.scss";

.new_cases_active {
    background-color: #1da6b8 !important;
    transition: all 1s ease-out;

    .el-collapse-item__header,
    .el-collapse-item__content {
        color: white;
    }
}

.new_cases {
    display: flex;
    justify-content: center;
    @include responsive-width(1200px);
    margin-top: 50px;

    .new_cases_left {
        flex: 1;
        margin-right: 50px;

        .left_title {
            font-weight: bold;
            font-size: 30px;
            color: #333333;
            margin: 20px 0 30px 30px;
        }

        .left_collapse_item ul li {

            position: relative;
            padding-left: 20px;

            &::before {
                content: "•";
                height: 23px;
                position: absolute;
                left: 0;
                top: 0;
                font-size: 24px;
                transform: translateY(-50%);
                border-radius: 50%;
            }
        }

        .left_collapse {
            transition: all .3s;

            .el-collapse-item__header,
            .el-collapse-item__wrap {
                background-color: transparent;
                padding: 0 30px;
            }

            .el-collapse-item__header {
                font-size: 18px;

                & i {
                    display: none;
                }
            }

            .title_box {
                width: 100%;

                .title_text {
                    width: fit-content;
                }
            }
        }
    }

    .new_cases_right {
        position: relative;

        img {
            object-fit: contain;
            width: 606px;
            height: 461px;
            box-shadow: -1px 1px 11px 2px #0000003b;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(221, 221, 221, 0.664);
            opacity: 0;
            transition: opacity 0.5s ease;
        }

        .fade-in {
            opacity: 1;
        }
    }
}

@media (max-width: 768px) {
    .new_cases {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        .new_cases_left {
            margin-right: 0;
            margin-bottom: 20px;

            .left_title {
                margin: 20px 0 30px 0;
                font-size: 24px;
            }
        }

        .new_cases_right {
            width: 100%;
        }

        .new_cases_right img {
            width: 100%;
            height: auto;
        }
    }
}
</style>